/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Fragment, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import { i18nCountry, i18nLanguage } from "@trueskin-web/locales"
import { jsx } from "@trueskin-web/theme"
import { useTranslation } from "@trueskin-web/translations"

import Blocks from "../block-library/components/blocks"
import SEODetails from "../block-library/components/seo-details"
import Hero from "../block-library/hero"
import Highlights from "../block-library/highlights"
import PrStrip from "../block-library/pr-strip"
import Prefooter from "../block-library/prefooter"
import Reviews from "../block-library/reviews"
import TreatmentGoals from "../block-library/treatment-goals"
import Layout from "../components/layout"

const IndexPage = ({
  data: {
    strapi: { homePage },
  },
}) => {
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    trackingClient.trackCampaignIfPresent(location)
  }, [location])

  return (
    <Fragment>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalOrganization",
            name: t("Schemas.pages.name"),
            alternateName: "FORMEL Skin",
            url: process.env.GATSBY_WEBSITE_URL,
            logo: {
              url: `${process.env.GATSBY_WEBSITE_URL}/logo.png`,
              caption: "FORMEL Skin",
            },
            description: t("Schemas.pages.description"),
            knowsLanguage: i18nLanguage(),
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: t("Schemas.pages.telephone"),
                hoursAvailable: t("Schemas.pages.telephoneHoursAvailable"),
                contactType: "customer service",
                areaServed: i18nCountry(),
                availableLanguage: t(
                  "Schemas.pages.telephoneAvailableLanguage",
                  {
                    returnObjects: true,
                  }
                ),
                description: t("Schemas.pages.telephoneDescription"),
              },
              {
                "@type": "ContactPoint",
                email: t("Schemas.pages.email"),
                contactType: "customer service",
                contactOption: "TollFree",
                areaServed: i18nCountry(),
                availableLanguage: t("Schemas.pages.emailAvailableLanguage", {
                  returnObjects: true,
                }),
                description: t("Schemas.pages.emailDescription"),
              },
            ],
            sameAs: t("Schemas.pages.sameAs", {
              returnObjects: true,
            }),
          }),
        }}
      />

      <Layout
        title={homePage.title}
        navigationConfig={homePage.navigationConfig}
      >
        <SEODetails title={homePage.title} {...homePage.seoDetails} />

        {homePage.blocks?.length ? (
          <Blocks blocks={homePage.blocks} />
        ) : (
          <Fragment>
            <Hero {...homePage.hero} onHomepage />
            <TreatmentGoals {...homePage.treatmentGoals} />
            <Highlights {...homePage.highlights} onHomepage />
            <Reviews {...homePage.reviews} onHomepage />
            <Prefooter {...homePage.prefooter} />
            <PrStrip {...homePage.prStrip} />
          </Fragment>
        )}
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  {
    strapi {
      homePage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        hero {
          ...HeroFragment
        }
        treatmentGoals {
          ...TreatmentGoalsFragment
        }
        highlights {
          ...HighlightsFragment
        }
        reviews {
          ...ReviewsFragment
        }
        prefooter {
          ...PrefooterFragment
        }
        prStrip {
          ...PrStripFragment
        }
        navigationConfig {
          showTopNavigationBar
          showTopNavigationHeader
          isTopNavigationBarFloating
        }
        blocks {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksBannerUsp {
            ...BannerUspFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
          ... on Strapi_ComponentBlocksNewsletterSignup {
            ...NewsletterSignupFragment
          }
        }
      }
    }
  }
`

export default IndexPage
